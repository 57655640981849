





















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { deleteRecommendation, getRecommendation, saveRecommendation } from '@/api/recommendations'
import Sticky from '@/components/Sticky/index.vue'
import { Recommendation } from '@/models/Recommendation'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { confirmDialog, errorMsg, successMsg, validateForm } from '@/utils'
import { Form } from 'element-ui'
import { redirectToEditUrl } from '@/utils/recommendation'
import { Client } from '@/models/Client'
import { Property } from '@/models/Property'

@Component({
  name: 'recommendationDetail',
  components: {
    Sticky,
    PropertyCard: () => import(/* webpackChunkName: "collaboration-property-card" */ '@/views/recommendation/components/PropertyCard.vue'),
    DetailsCard: () => import(/* webpackChunkName: "collaboration-details-card" */ '@/views/recommendation/components/DetailsCard.vue'),
    ClientCard: () => import(/* webpackChunkName: "collaboration-client-card" */ '@/views/recommendation/components/ClientCard.vue')
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  @Prop({ default: false }) private isProperty!: boolean

  private loading = false
  private skeletonLoader = false
  private col = this.isProperty ? 8 : 12
  private recommendation = new Recommendation({
    user_id_from: UserModule.id,
    office_id_from: UserModule.officeId,
    type: this.isProperty ? 'property' : 'client',
    meta: {
      client: new Client(),
      property: this.isProperty ? new Property() : null
    },
    status: 0
  })

  created() {
    if (this.isEdit) {
      this.getItem(this.$route.params && this.$route.params.id)
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getRecommendation({ id })
      this.recommendation = data
    } catch (err) {}
    this.skeletonLoader = false
  }

  private async deleteItem() {
    const [data] = await confirmDialog('form.apiDelete')
    if (!data) return
    try {
      await deleteRecommendation({
        id: this.recommendation.id
      })
      successMsg('form.apiDeleteSuccess')
      return this.$router.push('/outgoing-recommendation/list')
    } catch (err) {
      errorMsg('api.serverError')
    }
  }

  private normalizeItemForSave() {
    const clientCard = this.$refs.clientCard as any
    clientCard.normalizeItemForSave()
    this.recommendation.user_from = null
    this.recommendation.user_to = null
    this.recommendation.office_from = null
    this.recommendation.office_to = null
  }

  private async validateForms() {
    let isValid = true
    for (const ref of Object.keys(this.$refs)) {
      const card = this.$refs[ref] as any
      const [isFormValid] = await validateForm(card.$refs.cardForm as Form)
      isValid = isValid && isFormValid
    }
    return isValid
  }

  private async saveItem() {
    const isValid = await this.validateForms()
    if (!isValid) return errorMsg('form.formErrors')
    this.loading = true
    try {
      this.normalizeItemForSave()
      const { data } = await saveRecommendation(this.recommendation)
      if (!this.isEdit) return this.$router.push(redirectToEditUrl(data))
      successMsg('actions.apiSuccessSave')
    } catch (err) {
      errorMsg('api.serverError')
    }
    this.loading = false
  }
}
