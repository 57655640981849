import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getRecommendations = (data: any) =>
  request({
    method: 'post',
    url: '/api/recommendations/getRecommendations',
    data
  })

export const getRecommendationsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/recommendations/getRecommendationsCount',
    data
  })

export const getRecommendation = (params: any) =>
  request({
    method: 'get',
    url: '/api/recommendations/getRecommendation',
    params
  })

export const saveRecommendation = (data: any) =>
  request({
    method: 'post',
    url: '/api/recommendations/saveRecommendation',
    data
  })

export const transferUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/recommendations/transferUser',
    data
  })

export const saveRecommendationBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/recommendations/saveRecommendationBulk',
    data
  })

export const deleteRecommendation = (params: any) =>
  request({
    method: 'get',
    url: '/api/recommendations/deleteRecommendation',
    params
  })

export const deleteRecommendationBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/recommendations/deleteRecommendationBulk',
    data
  })

export const approveRecommendation = (params: any) =>
  request({
    method: 'get',
    url: '/api/recommendations/approveRecommendation',
    params
  })

export const cancelRecommendation = (params: any) =>
  request({
    method: 'get',
    url: '/api/recommendations/cancelRecommendation',
    params
  })

export const rejectRecommendation = (data: any) =>
  request({
    method: 'post',
    url: '/api/recommendations/rejectRecommendation',
    data
  })

export const exportRecommendations = (recommendations: any) => {
  const fields = [
    {
      key: 'property',
      type: 'custom',
      parse: (item: any) => {
        if (item.property) {
          return item.property.full_code
        }
        return ''
      }
    },
    {
      key: 'user_from',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user_from)
      }
    },
    {
      key: 'user_to',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user_to)
      }
    },
    {
      key: 'office_from',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.office_from, ['name'])
      }
    },
    {
      key: 'office_to',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.office_to, ['name'])
      }
    },
    {
      key: 'client',
      type: 'custom',
      parse: (item: any) => {
        if (item.client) {
          return getTranslations(item.client)
        }
        return ''
      }
    },
    {
      key: 'notes'
    },
    {
      key: 'date_created',
      type: 'timestamp'
    }
  ]
  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, recommendations),
    'recommendation-list'
  )
}
