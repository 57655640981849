/* eslint-disable camelcase */
export class Recommendation {
  id: string | null;
  type: number | null;
  status: number | null;
  user_id_from: string | null;
  user_from: string | null;
  user_id_to: string | null;
  user_to: string | null;
  office_id_from: string | null;
  office_from: string | null;
  office_id_to: string | null;
  office_to: string | null;
  meta: any;

  constructor(data: any = {}) {
    this.id = data.id || null
    this.type = data.type || null
    this.status = data.status || null
    this.user_id_from = data.user_id_from || null
    this.user_from = data.user_from || null
    this.user_id_to = data.user_id_to || null
    this.user_to = data.user_to || null
    this.office_id_from = data.office_id_from || null
    this.office_from = data.office_from || null
    this.office_id_to = data.office_id_to || null
    this.office_to = data.office_to || null
    this.meta = data.meta || { client: {}, property: {} }
  }
}
